import { Compliance, CspError, emptyCompliance, Maybe, RestOptions } from '@csp/csp-common-model';
import { getMeta } from '@csp/csp-common-util';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import { ComplianceV1, createPatientComplianceMetaV1, UserMetaV1, UserV1 } from '@csp/dmdp-api-user-dto';
import { ComplianceMapper } from '../mapper/ComplianceMapper';

const getComplianceV1 = ({ metas: userV1Metas }: UserV1): Maybe<ComplianceV1> => {
  const metas = userV1Metas as Maybe<UserMetaV1[]>;
  return getMeta(metas, 'PATIENT_COMPLIANCE_V1')?.data;
};

const getPatientCompliance = (userV1: UserV1): Compliance => {
  const complianceV1 = getComplianceV1(userV1);
  return complianceV1 ? ComplianceMapper.toCompliance(complianceV1) : emptyCompliance;
};

const updatePatientCompliance = async (
  userV1: UserV1,
  compliance: Compliance,
  { axios }: RestOptions,
): Promise<void> => {
  const metaV1 = createPatientComplianceMetaV1(ComplianceMapper.toComplianceV1(compliance));
  try {
    await UserRestServiceV1.addOrUpdateMeta({ axios }, userV1, metaV1);
  } catch (error) {
    if (CspError.isConflict(error)) {
      await UserRestServiceV1.updateMeta({ axios }, userV1.userId, metaV1);
    } else {
      throw error;
    }
  }
};

export const PatientComplianceMetaService = {
  getPatientCompliance,
  updatePatientCompliance,
};
