import { RoleType } from '@csp/dmdp-api-common-dto';
import { ContentRole } from '../models/ContentRole';

const hcpContentRoles = [
  RoleType.CRA,
  RoleType.PI,
  RoleType.INVESTIGATOR,
  RoleType.STUDY_ASST,
  RoleType.READ_ALL,
  RoleType.SSTM,
  RoleType.GSTM,
  RoleType.SERVICE_CSA,
];

const patientContentRoles = [RoleType.PATIENT, RoleType.SERVICE_CSA];

const isHcpContentRole = (roleTypes: RoleType[]): boolean =>
  hcpContentRoles.some(role => roleTypes.find(r => r) === role);

const isPatientContentRole = (roleTypes: RoleType[]): boolean =>
  patientContentRoles.some(role => roleTypes.find(r => r) === role);

export const getContentRole = (roleTypes: RoleType[]): ContentRole[] => {
  const roles: ContentRole[] = [];

  if (isHcpContentRole(roleTypes)) {
    roles.push('hcp');
  }
  if (isPatientContentRole(roleTypes)) {
    roles.push('patient');
  }
  return roles;
};
