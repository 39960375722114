import {
  ConsentService,
  CspConsentClientType,
  InitializationConfig,
  InitializationService,
  localeActions,
} from '@csp/common';
import { CspConfigService } from '@csp/csp-api-client';
import {
  CachePolicy,
  ContentCacheObject,
  ContentPackageType,
  ContentstackConfig,
  ContentstackConfigService,
  getPackageVersionFromUnifyVersion,
} from '@csp/csp-common-content-delivery-client';
import { ClientType } from '@csp/csp-common-model';
import { getUnifyVersion } from '@csp/csp-common-unify-version';
import { TelemetryService } from '@csp/csp-web-telemetry';
import { DmdpConfigService, createDmdpPodConfigs } from '@csp/dmdp-api-client';
import { BroadcastChannelService, WebSignOutService } from '@csp/web-common';
import debug from 'debug';
import { shouldAllowContentPreview } from '@csp/csp-common-util';
import { environment } from '../../environments/environment';
import { commonSelector, store } from '../../state/store';
import { EnvConfig } from '../model/EnvConfig';
import { overridesEnvConfigFromParams } from '../util/overridesEnvConfigFromParams';
import { createCspConfig } from './CspConfigService';
import { LocalStorageCacheProviderFactory } from './LocalStorageCacheProvider';
import { TealiumService } from './TealiumService';
import { MobileConfigService } from './MobileConfigService';

const log = debug('Web:InitializationService');

const init = async (appVersion: string, urlSearchParams?: URLSearchParams): Promise<void> => {
  console.debug(`App version: ${appVersion}`);

  const envConfigResponse = await fetch(`${environment.baseUrl}/asset/env-config.json`);
  const envConfigJson = await envConfigResponse.json();
  const envConfigJsonWithOverrides = overridesEnvConfigFromParams(envConfigJson, urlSearchParams);

  const {
    zoom,
    cognito,
    cspEnv = 'dev',
    dmdp,
    cms,
    telemetryConfigs,
    content,
    mobileApp,
  }: EnvConfig = envConfigJsonWithOverrides;

  const { tealiumEnv, hideSchemaValidationMessages } = createCspConfig(cspEnv);
  TealiumService.init(tealiumEnv);

  if (telemetryConfigs?.sentry?.enabled) {
    TelemetryService.init({
      ...telemetryConfigs.sentry,
      release: appVersion,
    });
  }

  ConsentService.setConsentServiceConfig({ clientType: CspConsentClientType.PORTAL });

  DmdpConfigService.setDmdpPodConfigs({
    ...createDmdpPodConfigs(dmdp.baseUrl, dmdp.pods.messagingpodemail?.baseUrl || ''),
  });

  MobileConfigService.set(mobileApp);

  DmdpConfigService.setMqttConfig({ ...dmdp.mqtt });

  CspConfigService.setCspPodConfigs({
    csppod: {
      baseUrl: dmdp.pods.csppod?.baseUrl || '',
    },
    contentdeliverypod: {
      baseUrl: dmdp.pods.contentdeliverypod?.baseUrl || '',
    },
  });

  const initConfig: InitializationConfig = {
    getCognitoConfig: async () => ({
      options: cognito,
      signOut: WebSignOutService.webSignOutCognito,
    }),
    getDmdpConfig: async () => dmdp,
    getContentConfig: async () => ({ hideSchemaValidationMessages, ...cms }),
    commonSelector,
    getZoomConfig: async () => zoom,
  };

  const cacheProvider = LocalStorageCacheProviderFactory<ContentPackageType, ContentCacheObject>();
  await cacheProvider.loadFromDisk();

  const mockContent: ContentstackConfig['mockContent'] = content.allowMockedContent
    ? (await import('./mockContent')).mockContent
    : {
        public: {},
        generic: {},
        study: {},
      };

  ContentstackConfigService.set({
    ...content,
    baseUrl: CspConfigService.getCspPodConfigs().contentdeliverypod.baseUrl,
    clientType: ClientType.HCP_WEB,
    locale: 'en_gb',
    allowContentPreview: shouldAllowContentPreview(),
    packageVersions: {
      public: getPackageVersionFromUnifyVersion(getUnifyVersion()),
      generic: getPackageVersionFromUnifyVersion(getUnifyVersion()),
      // Study content only exists for 1_0
      study: '1_0',
    },
    validateContent: false,
    hideSchemaValidationMessages: true,
    mockContent,
    cacheProvider: content?.forceContentPreview ? undefined : cacheProvider,
    cachePolicy: CachePolicy.CACHE_AND_NETWORK,
    role: 'hcp', // HCP is used as a catch-all for all roles querying for study based content from the portal.
  });

  log('initConfig', initConfig);

  await InitializationService.init(initConfig);
  store.dispatch(localeActions.setOverrideLang('en'));

  BroadcastChannelService.createChannel('unify');
};

export const WebInitializationService = {
  init,
};
