import { EnumUtil } from '@csp/csp-common-enum-util';
import { EventIdStr, Maybe, ObservationId, StateAssert, UserId, ZonedDateTime } from '@csp/csp-common-model';
import { Assessment } from './Assessment';
import { AssessmentAlgorithm } from './AssessmentAlgorithm';
import { AssessmentCustomStatus } from './AssessmentCustomStatus';
import { AssessmentCustomStatusType } from './AssessmentCustomStatusType';
import { AssessmentCustomStatusValue } from './AssessmentCustomStatusValue';
import { AssessmentAlgorithmResult } from './AssessmentAlgorithmResult';
import { AssessmentId } from './AssessmentId';
import { AssessmentOutcomeKey } from './AssessmentOutcomeKey';
import { AssessmentRefKey } from './AssessmentRefKey';
import { RpmAssessmentType } from './RpmAssessmentType';

type QResponseId = string;

export type RpmAssessment = {
  algorithm: AssessmentAlgorithm;
  algorithmResult: AssessmentAlgorithmResult;
  assessmentId: AssessmentId;
  assessmentType: RpmAssessmentType;
  createdAt: Maybe<ZonedDateTime>;
  dataRefId: Maybe<ObservationId | QResponseId>;
  eventId: EventIdStr;
  isViewed: boolean;
  userId: Maybe<UserId>;
  viewedAt: Maybe<ZonedDateTime>;
  viewedBy: Maybe<UserId>;
};

const getViewedStatusFrom = (customStatuses: AssessmentCustomStatus[]): Maybe<AssessmentCustomStatus> =>
  customStatuses.find(
    status =>
      status.type === AssessmentCustomStatusType.VIEW_STATUS && status.value === AssessmentCustomStatusValue.VIEWED,
  );

const from = ({
  assessmentId,
  assessmentType,
  assessmentOutcomes,
  createdTimestamp,
  customStatuses,
  eventId,
  refs,
  userId,
}: Assessment): RpmAssessment => {
  const outcome = assessmentOutcomes.find(({ key }) => key === AssessmentOutcomeKey.algorithmResult);
  const viewedStatus = getViewedStatusFrom(customStatuses);

  StateAssert.isDefined(
    outcome?.algorithm,
    `Encountered an RPM Assessment without defined algorithm information. AssessmentId: ${assessmentId}`,
  );

  StateAssert.isDefined(
    assessmentType,
    `Encountered an RPM Assessment without defined assessment type. AssessmentId: ${assessmentId}`,
  );

  const type = EnumUtil.fromStringOrError<RpmAssessmentType>(assessmentType, RpmAssessmentType);
  const dataRefId = refs
    .reverse()
    .find(ref =>
      type === RpmAssessmentType.RPM_DEVICE_EVENT
        ? ref.key === AssessmentRefKey.DEVICE_OBSERVATION_ID
        : ref.key === AssessmentRefKey.QUESTIONNAIRE_RESPONSE_ID,
    )?.value;

  return {
    algorithm: outcome?.algorithm,
    algorithmResult: EnumUtil.fromMaybeStringOrDefault(
      outcome?.value,
      AssessmentAlgorithmResult,
      AssessmentAlgorithmResult.NO_ALGORITHM_RESULT,
    ),
    assessmentId,
    assessmentType: type,
    createdAt: createdTimestamp,
    dataRefId,
    eventId,
    isViewed: !!viewedStatus,
    userId,
    viewedAt: viewedStatus?.createdAt,
    viewedBy: viewedStatus?.createdBy,
  };
};

export const RpmAssessment = {
  from,
};
