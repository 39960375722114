import { RpmAssessment, RpmAssessmentService, RpmEventState } from '@csp/csp-common-assessment';
import { ZonedDateTimeFactory } from '@csp/csp-common-date-util';
import { ApiOptions, CspError, emptyRpmEventStatus, Maybe, Patient, RpmEventStatus } from '@csp/csp-common-model';
import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import { getMeta } from '@csp/csp-common-util';
import { toRestOptions } from '@csp/csp-fe-auth';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import { createPatientRpmEventMetaV1, RpmEventStatusV1, UserMetaV1, UserV1 } from '@csp/dmdp-api-user-dto';
import { head } from 'lodash';

const getRpmEventStatusV1 = ({ metas: userV1Metas }: UserV1): Maybe<RpmEventStatusV1> => {
  const metas = userV1Metas as Maybe<UserMetaV1[]>;
  return getMeta(metas, 'PATIENT_RPM_STATUS_V1')?.data;
};

const getPatientRpmEventStatus = (userV1: UserV1): RpmEventStatus => {
  const rpmEventStatusV1 = getRpmEventStatusV1(userV1);
  return rpmEventStatusV1
    ? {
        updatedAt: rpmEventStatusV1.updatedAt,
        state: rpmEventStatusV1.state,
      }
    : emptyRpmEventStatus;
};

const updatePatientRpmEventStatus = async (
  userV1: UserV1,
  rpmEventStatus: RpmEventStatus,
  apiOptions?: ApiOptions,
): Promise<void> => {
  const restOptions = toRestOptions(apiOptions);
  const userMetaV1 = createPatientRpmEventMetaV1(rpmEventStatus);
  try {
    await UserRestServiceV1.addOrUpdateMeta(restOptions, userV1, userMetaV1);
  } catch (error) {
    if (CspError.isConflict(error)) {
      await UserRestServiceV1.updateMeta(restOptions, userV1.userId, userMetaV1);
    } else {
      throw error;
    }
  }
};

const updateMeta = async (patient: Patient, rpmAssessment: RpmAssessment, apiOptions?: ApiOptions): Promise<void> => {
  const eventStatus: RpmEventStatus = {
    state: RpmEventState.fromAssessments([rpmAssessment]),
    updatedAt: ZonedDateTimeFactory.now(),
  };
  await updatePatientRpmEventStatus(patient.userV1, eventStatus, apiOptions);
};

const syncLatestAssessmentAndUpdateMeta = async (
  patient: Patient,
  algorithmCodes: RpmAlgorithmCode[],
): Promise<void> => {
  const latestRpmAssessment = head(
    await RpmAssessmentService.queryLatestByPatientIdAndAlgorithmCode(patient.userId, algorithmCodes),
  );
  if (latestRpmAssessment) {
    await updateMeta(patient, latestRpmAssessment);
  }
};

export const PatientRpmEventMetaService = {
  getPatientRpmEventStatus,
  updatePatientRpmEventStatus,
  updateMeta,
  syncLatestAssessmentAndUpdateMeta,
};
