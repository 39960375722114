import { studyService } from '@csp/common';
import { MemCacheService } from '@csp/csp-common-memcache';
import { StudyComplianceService } from '@csp/csp-common-metrics';
import { UserService } from '@csp/csp-common-user';
import { DmdpTokenService } from '@csp/csp-fe-auth';
import { RoleType } from '@csp/dmdp-api-common-dto';
import { AccessRefType, RoleAccessRefsV1 } from '@csp/dmdp-api-user-dto';
import { ErrorService } from '@csp/web-common';
import { compact } from 'lodash/fp';
import { GenericContentService } from '../../../common/service/GenericContentService';
import { StudyContentService } from '../../../common/service/StudyContentService';
import { LoginPayload } from '../model/LoginPayload';
import { OrgPermission } from '../model/OrgPermission';
import { Principal } from '../model/Principal';
import { PermissionConfigService } from './PermissionConfigService';
import { PermissionService } from './PermissionService';

const toPermissionsByOrg = (roleAccessRefs: RoleAccessRefsV1[]): OrgPermission[] => {
  // Populate user's permissions
  const permissionsByOrgId = new Map<string, OrgPermission>();
  roleAccessRefs.forEach(roleAccessRef =>
    roleAccessRef.accessRefs
      ? roleAccessRef.accessRefs
          .filter(accessRef => accessRef.type === AccessRefType.ORGANISATION)
          .forEach(accessRef => {
            const orgId = accessRef.ref;
            let orgPermission: OrgPermission | undefined = permissionsByOrgId.get(orgId);
            if (!orgPermission) {
              orgPermission = {
                orgId,
                roles: [],
                permissions: [],
              };
              permissionsByOrgId.set(orgId, orgPermission);
            }
            const role = roleAccessRef.role as RoleType;
            orgPermission.roles.push(role);
            orgPermission.permissions.push(...PermissionConfigService.getPermissionsByRole(role));
          })
      : [],
  );
  return Array.from(permissionsByOrgId.values());
};

const loginDmdpAndLoadUser = async ({ tenantId, ttlSecs }: LoginPayload): Promise<Principal> => {
  MemCacheService.reset(); // Clear study related cached data
  const dmdpToken = await DmdpTokenService.login(tenantId, ttlSecs);

  const user = await UserService.getMine();

  // Start loading content package early and await it in the end
  // Only request study content package if user is logged in and activated
  const asyncContentPackageRequests = compact([
    GenericContentService.init(),
    user.isActive ? StudyContentService.init() : undefined,
  ]);

  const study = await studyService.getStudyOrStudyInfo(dmdpToken.getTenantId(), user);

  if (user.isActive) {
    // Preload this information to the cache.
    StudyComplianceService.getSiteIdsWithComplianceCachedByTenant(
      study.tenantId,
      study.sites.map(site => site.orgId),
    )
      .then(siteIdsWithCompliance => study.mutateSiteIdsWithCompliance(siteIdsWithCompliance))
      .catch(error => ErrorService.handleError({ error }));
  }

  studyService.setActiveStudy(study);

  console.debug('Tenant ID: ', study.tenantId);
  console.debug('User: ', user.email);
  console.debug('User roles: ', user.roles.join(', '));

  const orgPermissions = toPermissionsByOrg(user.userV1.roleAccessRefs);
  PermissionService.setPermissions(orgPermissions);

  await Promise.all(asyncContentPackageRequests);

  return {
    dmdpToken,
    orgPermissions,
    study,
    user,
  };
};

export const AuthService = {
  loginDmdpAndLoadUser,
};
