export type AssessmentOutcomeKey = 'bestResultFlag' | 'algorithmResult' | `consequence:${number}`;

const from = (value: string): AssessmentOutcomeKey => {
  if (
    value !== AssessmentOutcomeKey.bestResultFlag &&
    value !== AssessmentOutcomeKey.algorithmResult &&
    !keyIsConsequence(value)
  ) {
    throw new Error(`Unexpected value as 'AssessmentOutcomeKey': ${value}`);
  }

  return value;
};

export const AssessmentOutcomeKey = {
  bestResultFlag: 'bestResultFlag',
  algorithmResult: 'algorithmResult',
  consequence: 'consequence',
  from,
} as const;

const keyIsConsequence = (value: string): value is AssessmentOutcomeKey => /consequence:(\d+)/.test(value);
