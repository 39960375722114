import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import {
  AssessmentAggregateCriteriaFieldV1,
  AssessmentAggregateGroupFieldV1,
  AssessmentAggregateIncludeFieldV1,
  AssessmentAggregateQueryOperatorV1,
  AssessmentAggregateQueryTypeV1,
  AssessmentAggregateV1,
} from '@csp/dmdp-api-assessment-dto';
import { AssessmentCustomStatusType } from '../model/AssessmentCustomStatusType';
import { AssessmentCustomStatusValue } from '../model/AssessmentCustomStatusValue';
import { AssessmentOutcomeKey } from '../model/AssessmentOutcomeKey';
import { AssessmentAlgorithmResult } from '../model/AssessmentAlgorithmResult';

const allByAlgorithmCodesQuery = (algorithmCodes: readonly RpmAlgorithmCode[]): AssessmentAggregateV1 => ({
  aggregateFields: [
    AssessmentAggregateIncludeFieldV1.COUNT,
    AssessmentAggregateIncludeFieldV1.MAX_ASSESSMENT_TIMESTAMP,
    AssessmentAggregateIncludeFieldV1.MAX_EVENT_ID,
  ],
  group: {
    fields: [
      AssessmentAggregateGroupFieldV1.USER_ID,
      AssessmentAggregateGroupFieldV1.ASSESSMENT_ALGORITHM_CODE,
      AssessmentAggregateGroupFieldV1.CUSTOM_STATUS_VALUE,
      AssessmentAggregateGroupFieldV1.CUSTOM_STATUS_TYPE,
    ],
  },
  match: {
    criterion: {
      operator: AssessmentAggregateQueryOperatorV1.AND,
      criteria: [
        {
          fieldCriterion: {
            field: AssessmentAggregateCriteriaFieldV1.ALGORITHM_CODE,
            type: AssessmentAggregateQueryTypeV1.IN,
            values: [...algorithmCodes],
          },
        },
        {
          operator: AssessmentAggregateQueryOperatorV1.AND,
          criteria: [
            {
              fieldCriterion: {
                field: AssessmentAggregateCriteriaFieldV1.OUTCOME_KEY,
                type: AssessmentAggregateQueryTypeV1.EQ,
                values: [AssessmentOutcomeKey.algorithmResult],
              },
            },
            {
              fieldCriterion: {
                field: AssessmentAggregateCriteriaFieldV1.OUTCOME_VALUE,
                type: AssessmentAggregateQueryTypeV1.EQ,
                values: [AssessmentAlgorithmResult.ALGORITHM_RESULT_NOT_OK],
              },
            },
          ],
        },
        {
          operator: AssessmentAggregateQueryOperatorV1.AND,
          criteria: [
            {
              fieldCriterion: {
                field: AssessmentAggregateCriteriaFieldV1.CUSTOM_STATUS_TYPE,
                type: AssessmentAggregateQueryTypeV1.EQ,
                values: [AssessmentCustomStatusType.VIEW_STATUS],
              },
            },
            {
              fieldCriterion: {
                field: AssessmentAggregateCriteriaFieldV1.CUSTOM_STATUS_VALUE,
                type: AssessmentAggregateQueryTypeV1.IN,
                values: [AssessmentCustomStatusValue.NOT_VIEWED, AssessmentCustomStatusValue.VIEWED],
              },
            },
          ],
        },
      ],
    },
    // TODO[!DeviceSquad!] add sort back when the api supports it
    // sort: [
    //   {
    //     field: AssessmentAggregateSortFieldV1.CREATED_AT,
    //     subField: AssessmentAggregateSortFieldV1.CREATED_AT,
    //     order: AssessmentAggregateQuerySortOrderV1.DESC,
    //   },
    //   {
    //     field: AssessmentAggregateSortFieldV1.CUSTOM_STATUS_VALUE,
    //     subField: AssessmentAggregateSortFieldV1.CUSTOM_STATUS_VALUE,
    //     order: AssessmentAggregateQuerySortOrderV1.ASC,
    //   },
    // ],
  },
});

export const RpmAssessmentAggregateQuery = {
  allByAlgorithmCodesQuery,
};
