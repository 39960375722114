import { fromTimezoneStr } from '@csp/csp-common-date-util';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { EventIdStr, type Maybe, UserId, ZonedDateTime } from '@csp/csp-common-model';
import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import {
  type AssessmentAggregateGroupResultFieldsV1,
  AssessmentAggregateGroupResultV1,
} from '@csp/dmdp-api-assessment-dto';
import { AssessmentCustomStatusType } from './AssessmentCustomStatusType';
import { AssessmentCustomStatusValue } from './AssessmentCustomStatusValue';

export type AssessmentAggregateGroup = {
  algorithmCode?: RpmAlgorithmCode;
  count: number;
  isViewed: boolean;
  latestCreatedAt?: ZonedDateTime;
  maxEventId?: EventIdStr;
  userId?: UserId;
};

/**
 * TODO[!DeviceSquad!]: remove when back-end has fixed the issue with the customStatusValue field in the assessment
 * aggregate query api.
 *
 * The back-end is returning a string with the status other statuses separated by a comma in the customStatusValue
 * field from the aggregate query api.
 *
 * Example:
 * ```typescript
 *  const customStatusType = 'VIEW_STATUS,ASSESSMENT_CONSEQUENCE_ACTIVITY_CARD_STATUS';
 *  const customStatusValue = '100_VIEWED,001_NOT_VIEWED';
 *
 *  const finalStatusAfterSplit = '100_VIEWED';
 * ```
 */
const getViewStatusValueFrom = (fields: AssessmentAggregateGroupResultFieldsV1): Maybe<string> => {
  const statusTypes = fields.customStatusType?.split(',');
  const indexOfViewStatus = statusTypes?.findIndex(type => type === AssessmentCustomStatusType.VIEW_STATUS);
  const statuses = fields.customStatusValue?.split(',') ?? [];
  return indexOfViewStatus !== undefined ? statuses[indexOfViewStatus] : undefined;
};

const from = ({
  count,
  fields,
  maxAssessmentTimestamp,
  maxEventId,
}: AssessmentAggregateGroupResultV1): AssessmentAggregateGroup => ({
  algorithmCode: EnumUtil.fromMaybeString<RpmAlgorithmCode>(fields.assessmentAlgorithmCode, RpmAlgorithmCode),
  count: count ?? 0,
  isViewed: getViewStatusValueFrom(fields) === AssessmentCustomStatusValue.VIEWED,
  latestCreatedAt: maxAssessmentTimestamp ? fromTimezoneStr(maxAssessmentTimestamp) : undefined,
  maxEventId,
  userId: fields.userId,
});

export const AssessmentAggregateGroup = {
  from,
};
